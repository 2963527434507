<template>
  <div class="about">
    <h1>What is Wellsum?</h1>
    <p>A next generation software
      platform for the Spa and Wellness
      Travel industry that will give hotels
      and spas competitive advantage,
      maximise revenue and reduce
      costs of their software.</p>
  </div>
</template>

<style>
.about {
  width: 50%;
  margin: auto;
}
</style>
